<template>
  <v-container>

    <h2>{{ $t('views.viewMerchantSettings.title') }} |</h2>
    <p class="text-h4">{{ merchantName }}</p>

    <!-- SECTION - MERCHANT INFO -->
    <span class="text-h3">商店資訊</span>
    <v-row>
      <v-col cols="12" md="4">
        <v-card outlined class="px-1">
          <v-card-title>{{ $t('views.merchants.contact') }}</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.merchants.merchantId') }}</td>
                  <td>{{ merchantId }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.merchants.phone') }}</td>
                  <td>{{ merchantInfo.phone }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.merchants.email') }}</td>
                  <td>{{ merchantInfo.email }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.merchants.address') }}</td>
                  <td>{{ merchantInfo.address }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card outlined class="px-1">
          <v-card-title>發票資訊</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.merchants.invoice.type') }}</td>
                  <td>
                    <span v-if="invoice.type=='b2b'">{{ $t('views.merchants.invoice.B2B') }}</span>
                    <span v-else-if="invoice.type=='b2c'">{{ $t('views.merchants.invoice.B2C') }}</span>
                    <span v-else>{{ invoice.type  }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.merchants.invoice.title') }}</td>
                  <td>{{ invoice.title }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.merchants.invoice.taxId') }}</td>
                  <td>{{ invoice.taxId }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.merchants.invoice.email') }}</td>
                  <td>{{ invoice.email }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card outlined class="px-1">
          <v-card-title>收款帳戶資訊</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.viewMerchantSettings.beneficiary.bankName') }}</td>
                  <td>{{ beneficiary.bankName }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.viewMerchantSettings.beneficiary.bankId') }}</td>
                  <td>{{ beneficiary.bankId }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.viewMerchantSettings.beneficiary.accountName') }}</td>
                  <td>{{ beneficiary.accountName }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.viewMerchantSettings.beneficiary.accountNo') }}</td>
                  <td>{{ beneficiary.accountNo }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.viewMerchantSettings.beneficiary.accountId') }}</td>
                  <td>{{ beneficiary.accountId }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <br/>

     <!-- SECTION - Commission Fee -->
    <!-- <template class="mb-2">
      <span class="text-h3">{{ $t('views.viewMerchantSettings.commissionFee') }}</span>
      <v-divider class="mb-2"></v-divider>
      <v-row>
        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete 
                      v-model="form.formula.model.formulaId" 
                      :items="formulas" 
                      item-text="name"
                      item-value="id"
                      hide-details>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn rounded depressed color="error" @click="updateCommissionFee">
                {{ $t('views.viewMerchantSettings.confirm') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template> -->

    <!-- SECTION - SPECIAL SETTINGS -->
    <span class="text-h3">Three-dimension Security</span>
    <v-divider class="mb-2"></v-divider>
    <v-row>
      <v-col cols="6" md="4">
        <v-switch inset v-model="settings.useTds" label="Enable"
          @change="setTds">
        </v-switch>
      </v-col>
    </v-row>

    <!-- SECTION - MERCHANT VERIFICATION -->
    <template class="mb-2" v-if="user.level == 'superadmin' || user.level == 'admin'">
      <span class="text-h3">Merchant Verification</span>
      <v-divider class="mb-2"></v-divider>
     
      <MerchantVerificationView :merchant-id="merchantId"></MerchantVerificationView>

    </template>

    <!-- SECTION - MERCHANT MIGRATION -->
    <template class="mb-2" v-if="user.level == 'superadmin'">
      <span class="text-h3">Migrate to Another Organization</span>
      <v-divider class="mb-2"></v-divider>
      <v-row>
        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col cols="12">
                    <v-select v-model="form.migrate.model.toOrgId" :items="organizations" hide-details
                      placeholder="The organization to migrate to">
                    </v-select>
                  </v-col>
                  <v-col cols="12" class="d-flex flex-column">
                    <span></span>
                    <v-checkbox label="Also migrate binded payment services?" hide-details
                      v-model="form.migrate.model.movePaymentServices">
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn rounded depressed color="error" @click="requestMigration">
                Confirm Migrate
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>


    <!-- SECTION - USER MANAGEMENT -->
    <span class="text-h3">{{ $t('views.viewMerchantSettings.employeeMngmt') }}</span>
    <v-divider class="mb-2"></v-divider>
    <v-row>
      <v-col cols="6">
        <v-card outlined class="px-1">
          <SSRTable ref="list-employees"
            :data-url="loadUsersUrl" :data-key="'employees'" :schema="usersSchema"
            selectable @select-row="onEmployeeSelectionChanged">
          </SSRTable>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-form :disabled="form.employee.isLoading">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-radio-group row v-model="form.employee.model.level" v-if="form.employee.mode == 'create'">
                  <v-radio :label="$t('views.viewMerchantSettings.employee')" value="employee"></v-radio>
                  <v-radio :label="$t('views.viewMerchantSettings.manager')" value="manager"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" class="grey--text d-flex align-center">
                <span v-if="form.employee.model.level == 'manager'">{{ $t('views.viewMerchantSettings.managerDesc') }}</span>
                <span v-else>{{ $t('views.viewMerchantSettings.employeeDesc') }}</span>
              </v-col>
          
              <v-col cols="12" md="6" v-if="form.employee.mode == 'create'">
                <v-text-field dense filled type="text" :label="$t('views.viewMerchantSettings.form.displayName')"
                  v-model="form.employee.model.displayName"
                  :error-messages="validation.firstError('form.employee.model.displayName')">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="form.employee.mode == 'create'">
                <v-text-field dense filled type="text" :label="$t('views.viewMerchantSettings.form.username')"
                  v-model="form.employee.model.username"
                  :error-messages="validation.firstError('form.employee.model.username')">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="form.employee.mode == 'create'">
                <v-text-field dense filled type="password" :label="$t('views.viewMerchantSettings.form.password')" 
                  v-model="form.employee.model.password"
                  :error-messages="validation.firstError('form.employee.model.password')">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <template v-if="form.employee.mode == 'create'">
                  <v-btn rounded color="warning" :disabled="form.employee.isLoading"
                    @click="addEmployee">
                    <v-icon left>mdi-account-plus</v-icon>
                    {{ $t('views.viewMerchantSettings.form.buttonAdd') }}
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn rounded color="error" :disabled="form.employee.isLoading"
                    @click="deleteEmployee">
                    <v-icon left>mdi-account-remove</v-icon>
                    {{ $t('views.viewMerchantSettings.form.buttonDelete') }}
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-container>

        </v-form>
      </v-col>
    </v-row>

    <br>
    
    <!-- SECTION - VAS QPOINTS -->
    <span class="text-h3">{{ $t('views.viewMerchantSettings.vasQpoints.title') }}</span>
    <v-divider class="mb-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="4">

        <!-- VAS - QPOINTS TTPUSH -->
        <v-card outlined class="mx-auto" :disabled="vas.ttpush.isLoading">
          <v-progress-linear indeterminate color="teal" v-if="vas.ttpush.isLoading"></v-progress-linear>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1">
                QPoints - TTPush
              </v-list-item-title>
              <v-list-item-subtitle>
                台東金幣兌換服務
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80">
              <img :src="require('@/assets/vas/ttpush-logo.svg')" alt="TTPush Logo"
                :class="{'disabled-filter': !vas.ttpush.connected}">
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions class="d-flex justify-end">
            <v-btn rounded color="warning" v-if="!vas.ttpush.connected"
              @click="requestConnectQPointsVendor('ttpush')">
              {{ $t('views.viewMerchantSettings.vasQpoints.buttonActivate') }}
            </v-btn>
            <v-btn rounded color="primary" v-if="vas.ttpush.connected"
              @click="goToExchangeRecords">
              {{ $t('views.viewMerchantSettings.vasQpoints.buttonViewExchanges') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>

    <!-- SECTION - VAS DONATIONS -->
    <span class="text-h3">Value-added Services - Miscellaneous</span>
    <v-divider class="mb-2"></v-divider>
    <v-row class="mb-2">
      <v-col cols="12" lg="6">
        <v-card outlined class="mx-auto" :disabled="vas.ttpush.isLoading">
          <v-progress-linear indeterminate color="teal" v-if="vas.ttpush.isLoading"></v-progress-linear>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1">
                捐一點 - 愛心隨手捐模組
              </v-list-item-title>
              <v-list-item-subtitle>
                Donate-per-payment Module
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80">
              <img :src="require('@/assets/vas/donation.png')" alt="Donation"
                :class="{'disabled-filter': !vas.donations.enabled}">
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions class="d-flex justify-space-between">
            <v-switch inset v-model="vas.donations.enabled" label="Enable?" @change="toggleVasConfig('donations')"></v-switch>
            <div>
              <v-btn rounded color="warning" v-if="vas.donations.enabled"
                @click="showDialogOfVasDonations">
                綁定公益社福單位
              </v-btn>
              <v-btn rounded color="primary" v-if="vas.donations.enabled"
                @click="goToDonationRecords">
                前往捐款記錄
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" lg="6">
        <v-card outlined class="mx-auto" :disabled="vas.ttpush.isLoading">
          <v-progress-linear indeterminate color="teal" v-if="vas.ttpush.isLoading"></v-progress-linear>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1">
                會員折扣 - 入會專屬消費折扣模組
              </v-list-item-title>
              <v-list-item-subtitle>
                Discount for New Membership
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80">
              <img :src="require('@/assets/vas/member-discount.png')" alt="Member Discount"
                :class="{'disabled-filter': !vas.memberDiscount.enabled}">
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions class="d-flex justify-space-between">
            <v-switch inset v-model="vas.memberDiscount.enabled" label="Enable?" @change="toggleVasConfig('memberDiscount')"></v-switch>

            <div>
              <!-- <v-btn rounded color="primary" v-if="vas.memberDiscount.enabled"
                @click="goToDonationRecords">
                前往折扣記錄
              </v-btn> -->
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- SUB-DIALOG - VAS DONATIONS CONFIGS -->
    <v-dialog v-model="dialog.donations.show" width="60%">
      <v-card>
        <v-card-title>
          捐一點設定：公益／社福機構｜{{ merchantName }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <v-autocomplete v-model="dialog.donations.charityId"
                label="公益／社福機構" prepend-icon="mdi-home-heart" clearable
                :loading="dialog.donations.isLoading"
                :items="dialog.donations.charities"
                item-text="donate_to" item-value="id"
                :search-input.sync="dialog.donations.searchKeyword"
                cache-items hide-no-data hide-details solo-inverted> 
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="6">
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-card-actions>
          <v-btn depressed color="error" @click="updateCharity">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import MerchantVerificationView from '@/views/dashboard/subviews/MchVerificationView.vue';

import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';

const Validator = SimpleVueValidation.Validator;

export default {
  name: 'MerchantSettings',
  components: {
    SSRTable,
    MerchantVerificationView
  },
  props: {
    merchantId: String,
    merchantName: String,
    merchant: Object
  },

  methods: {

    /// Settings methods
    setTds(enabled) {
      console.log('[DEBUG] setTds', enabled);
    },
    async fetchMerchant() {
      try {
        let response = await axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}`);
        this.merchantInfo = response.data;
        // this.form.formula.model.formulaId = this.merchantInfo.formula_id;
      } catch (err) {
        console.error(`Failed to fetch merchant info`, err);
        this.$notify({
          title: 'Cannot fetch merchant info!',
          type: 'error'
        });
        throw err;
      }
    },

    // async fetchMerchantBeneficiaries() {
    //   try {
    //     let response = await axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/beneficiaries`);
    //     this.beneficiary = response.data;
    //     this.form.formula.model.formulaId = this.beneficiary.formulaId;
    //   } catch (err) {
    //     console.error(`Failed to fetch beneficiary info`, err);
    //     this.$notify({
    //       title: 'Cannot fetch beneficiary info!',
    //       type: 'error'
    //     });
    //     throw err;
    //   }
    // },

    // async fetchCommissionFee() {
    //   this.form.formula.isLoading = true;
    //   try {
    //     let response = await axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/formulas`);
    //     this.formulas = response.data.formulas;
    //     this.form.formula.isLoading = false;
    //   } catch (err) {
    //     console.error(`Failed to fetch commission fee formulas`, err);
    //     this.form.formula.isLoading = false;
    //     this.$notify({
    //       title: 'Cannot fetch commission fee formulas!',
    //       type: 'error'
    //     });
    //     throw err;
    //   }
    // },

    // async updateCommissionFee() {
    //   if (!this.form.formula.model.formulaId) return;
    //   try {
    //     this.form.formula.isLoading = true;
    //     let updateRes = await axios.put(
    //       `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/formula`,
    //       {
    //         formulaId: this.form.formula.model.formulaId
    //       }
    //     );
    //     this.$notify({
    //       title: updateRes.data.message,
    //       type: 'success'
    //     });
    //     this.form.formula.isLoading = false;
    //   } catch (err) {
    //     console.error(`Failed to update commission fee formula`, err);
    //     this.form.formula.isLoading = false;
    //     this.$notify({
    //       title: 'Cannot update commission fee formula!',
    //       type: 'error'
    //     });
    //     throw err;
    //   }
    // },
    /// Migrate methods
    fetchOrganizations() {

    },
    requestMigration() {
      console.log(`[MCH-SETTINGS-VIEW]<DEBUG> requestMigration:`, this.form.migrate.model);
      let { toOrgId, movePaymentServices } = this.form.migrate.model;
      if (!toOrgId) return;
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/migrate`,
        { toOrgId, movePaymentServices }
      )
      .then(response => {
        this.$notify({
          text: `Migration has completed!`,
          type: 'success'
        });
      })
      .catch(err => {
        console.error(`[MCH-SETTINGS-VIEW] requestMigration: Failed to migrate merchant`, err);
        this.$notify({
          text: `Failed to migrate.`,
          type: 'error'
        });
      });
    },

    /// User management relating methods.
    onEmployeeSelectionChanged(employee, isSelected) {
      // If a selection is made, fill up the form for update.
      if (isSelected) {
        this.form.employee.mode = 'update';
        this.form.employee.userId = employee['id'];
        this.form.employee.model = {
          displayName: employee['display_name'], username: employee['username']
        };
      }
      // Otherwise, empty the form for creation.
      else {
        this.form.employee.mode = 'create';
        this.form.employee.userId = 0;
        this.form.employee.model = {
          displayName: '', username: '', password: ''
        };
      }
    },
    addEmployee() {
      this.$validate()
      .then(valid => {
        if (!valid) return;
        let model = this.form.employee.model;
        this.form.employee.isLoading = true;
        axios.post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/employees`,
          {
            displayName: model.displayName, email: model.username,
            username: model.username, password: model.password, level: model.level
          }
        )
        .then(response => {
          this.$refs['list-employees'].loadData();
          this.$notify({
            text: `${model.displayName} created.`,
            type: 'success'
          });
          //NOTE: Reset employee form
          this.form.employee.model.displayName = '';
          this.form.employee.model.username = '';
          this.form.employee.model.password = '';
        })
        .catch(err => {
          console.error(`Failed to create employee`, err.response);
          this.$notify({
            text: `Failed to create ${model.displayName}`,
            type: 'error'
          });
        })
        .finally(() => {
          this.form.employee.isLoading = false;
        });
      });
    },
    // updateEmployee() {
    //   let merchantId = this.merchant['merchant_id'];
    //   let userId = this.form.employee.userId;
    //   let model = this.form.employee.model;
    //   this.form.employee.isLoading = true;
    //   axios.put(
    //     `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${merchantId}/employees/${userId}`,
    //     {
    //       displayName: model.displayName, password: model.password,
    //     }
    //   )
    //   .then(response => {
    //     this.$refs['list-employees'].loadData();
    //     this.$notify({
    //       text: `${model.displayName} updated.`,
    //       type: 'success'
    //     });
    //   })
    //   .catch(err => {
    //     console.error(`Failed to update employee`, err.response);
    //     this.$notify({
    //       text: `Failed to update ${model.displayName}`,
    //       type: 'error'
    //     });
    //   })
    //   .finally(() => {
    //     this.form.employee.isLoading = false;
    //   });
    // },
    deleteEmployee() {
      let userId = this.form.employee.userId;
      let model = this.form.employee.model;
      if (!confirm(`Sure to delete user ${model.displayName}?`)) return;

      this.form.employee.isLoading = true;
      axios.delete(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/employees/${userId}`
      )
      .then(response => {
        this.$refs['list-employees'].loadData();
        this.form.employee.mode = 'create';
        this.$notify({
          text: `${model.displayName} deleted.`,
          type: 'warning'
        });
      })
      .catch(err => {
        console.error(`Failed to delete employee`, err.response);
        this.$notify({
          text: `Failed to delete ${model.displayName}`,
          type: 'error'
        });
      })
      .finally(() => {
        this.form.employee.isLoading = false;
        //NOTE: Reset employee form
        this.form.employee.model.displayName = '';
        this.form.employee.model.username = '';
        this.form.employee.model.password = '';
      });
    },

    /// VAS - QPOINTS related methods.
    fetchEnabledQPoints() {
      // Loading up all QPoints-related VAS.
      this.vas.ttpush.isLoading = true;
      // Make the request.
      axios.get(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/qpoints/merchants/${this.merchantId}/vendors`
      )
      .then(response => {
        console.log(`fetchEnabledQPoints`, response.data);
        let projects = response.data;
        projects.forEach(proj => {
          let vendor = proj['vendorCode'];
          if (this.vas[vendor]) this.vas[vendor].connected = true;
        });
      })
      .catch(err => {
        console.error(`fetchEnabledQPoints failed`, err);
      })
      .finally(() => {
        // Turn-off loading progresses.
        this.vas.ttpush.isLoading = false;
      });
    },
    requestConnectQPointsVendor(vendor) {
      this.vas.ttpush.isLoading = true;
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/qpoints/merchants/${this.merchantId}/vendors`,
        {
          "merchantName": this.merchantName,
          "pointCode": this.vas[vendor].pointCode
        }
      )
      .then(response => {
        console.log(`requestConnectQPointsVendor`, response.data);
      })
      .catch(err => {
        console.error(`requestConnectQPointsVendor failed`, err);
      })
      .finally(() => {
        this.vas.ttpush.isLoading = false;
        //FIXME: "Refresh all QPoints-related VAS by fetching again for connected services/vendors."
        this.fetchEnabledQPoints();
      });
    },
    goToExchangeRecords() {
      this.$router.push(`/vas/qpoints?merchantId=${this.merchantId}`);
    },

    /// VAS - Generic methods
    fetchVasConfigs() {
      axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/${this.merchantId}/configs`)
      .then(response => {
        console.log(`[MerchantSettingsView] fetchVasConfigs succeeded`, response.data);
        let data = response.data;
        // Set donations status
        if (data['donations']) {
          this.vas.donations.enabled = data['donations']['isEnabled'] == 1;
        }
        // Set member-discount status
        if (data['member-discount']) {
          this.vas.memberDiscount.enabled = data['member-discount']['isEnabled'] == 1;
          this.vas.memberDiscount.settings = data['member-discount']['settings'];
        }
      })
      .catch(err => {
        console.error(`[MerchantSettingsView] fetchVasConfigs failed`, err.response);
      });
    },
    toggleVasConfig(_vas) {
      // console.log(`toggleVasConfig @change`, this.vas.donations.enabled);
      axios.put(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/${this.merchantId}/configs/${_vas}`,
        { enable: this.vas[_vas].enabled, settings: this.vas[_vas].settings }
      )
      .then(response => {
        console.log(`[MerchantSettingsView] toggleVasConfig succeeded`, response.data);
      })
      .catch(err => {
        console.error(`[MerchantSettingsView] toggleVasConfig failed`, err.response);
        //FIXME: If error, we must fetch for the current remote configs again.
        this.fetchVasConfigs();
        this.$notify({
          type: 'error',
          text: 'Cannot toggle this configuration. Please try again later.'
        });
      });
    },


    /// VAS - Donations related methods
    showDialogOfVasDonations() {
      this.fetchBindedCharity();
      this.fetchDonationCharities();
      this.dialog.donations.show = true;
    },
    fetchDonationCharities() {
      this.dialog.donations.isLoading = true;
      axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/donations/charities`)
      .then(response => {
        // Fill in organization names
        let charities = response.data;
        charities.forEach(c => {
          c['donate_to'] = `${c['donate_to']} (${c['organization_name']})`;
        });
        this.dialog.donations.charities = charities;
      })
      .catch(err => {
        console.error(`[MerchantSettingsView] fetchDonationCharities: failed to fetch list of charities.`, err.response);
      })
      .finally(() => {
        this.dialog.donations.isLoading = false;
      });
    },
    fetchBindedCharity() {
      this.dialog.donations.isLoading = true;
      axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/donations/${this.merchantId}`)
      .then(response => {
        this.dialog.donations.charityId = response.data['id'];
      })
      .catch(err => {
        console.error(`[MerchantSettingsView] fetchBindedCharity: failed to fetch the bound charity of this merchant.`, err.response);
        this.dialog.donations.charityId = null;
      })
      .finally(() => {
        this.dialog.donations.isLoading = false;
      });
    },
    updateCharity() {
      this.dialog.donations.isLoading = true;
      let charityId = this.dialog.donations.charityId;
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/donations/charities/${charityId}/bind`,
        { merchantId: this.merchantId }
      )
      .then(response => {
        console.log(`[MerchantSettingsView] updateCharity succeeded.`, response.data);
        this.$notify({
          type: 'success', text: '成功更新了綁定的公益社福機構！'
        });
      })
      .catch(err => {
        console.error(`[MerchantSettingsView] updateCharity failed.`, err.response);
        this.$notify({
          type: 'error', text: '發生錯誤！無法更新綁定的公益社福機構。'
        });
      })
      .finally(() => {
        this.dialog.donations.isLoading = false;
        this.dialog.donations.show = false;
      });
    },
    goToDonationRecords() {
      this.$router.push(`/vas/donations?merchantId=${this.merchantId}`);
    },
  },

  data() {
    return {
      // merchant: { },//A cache of merchant data.
      formulas: [],
      merchantInfo: {},
      // beneficiary: {},
      settings: {
        useTds: false
      },

      form: {
        employee: {
          isLoading: false,
          mode: 'create',
          userId: 0,
          model: {
            displayName: '',
            username: '',
            password: '',
            level: 'employee'
          }
        },

        migrate: {
          isLoading: false,
          model: {
            toOrgId: null,
            movePaymentServices: false
          }
        },
        
        // formula: {
        //   isLoading: false,
        //   model: {
        //     formulaId: null,
        //   }
        // }
      },

      vas: {
        ttpush: {
          isLoading: false,
          connected: false,
          pointCode: 'ttpushv3'
        },

        donations: {
          isLoading: false,
          enabled: false,
          settings: { }
        },
        
        memberDiscount: {
          isLoading: false,
          enabled: false,
          setting: { }
        }
      },

      dialog: {
        donations: {
          show: false,
          isLoading: false,
          charityId: null,
          charities: [ ],
          searchKeyword: ''
        }
      }
    };
  },

  computed: {
    loadUsersUrl() {
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/employees`
        + `?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`;
    },
    usersSchema() {
      return [
        { value: 'level', text: this.$t('views.viewMerchantSettings.table.level') },
        { value: 'display_name', text: this.$t('views.viewMerchantSettings.table.displayName') },
        { value: 'username', text: this.$t('views.viewMerchantSettings.table.username') },
        { value: 'added_by_user_name', text: this.$t('views.viewMerchantSettings.table.addedBy') },
        { value: 'added_at', text: this.$t('views.viewMerchantSettings.table.addedAt') },
        { value: 'actions', text: this.$t('views.viewMerchantSettings.table.actions') },
      ];
    },

    user() {
      return this.$store.getters.user;
    },
    organizations() {
      return this.$store.getters.organizations.map(org => {
        return {
          text: org['name'],
          value: org['id']
        };
      });
    },
    beneficiary () {
      return this.merchantInfo?.beneficiary || {};
    },
    invoice () {
      return this.merchantInfo?.invoice || {};
      // return {
      //   type: this.beneficiary.invoiceType,
      //   taxId: this.beneficiary.invocieTaxId,
      //   title: this.beneficiary.invoiceTitle,
      //   email: this.beneficiary.invoiceEmail
      // }
      // {
      //   type: '',
      //   taxId: '',
      //   title: '',
      //   email: ''
      // }
    }

  },

  watch: {
    'merchantId': {
      immediate: true,
      handler(mId) {

        // Reset all settings.
        for (let vendor in this.vas) {
          this.vas[vendor].connected = false;
        }

        // Fetch necessary remote data.
        this.fetchEnabledQPoints();
        this.fetchVasConfigs();
        // this.fetchCommissionFee();
        this.fetchMerchant();
        // this.fetchMerchantBeneficiaries();
      }
    }
  },

  validators: {
    'form.employee.model.displayName': v => {
      return Validator.value(v).required().minLength(4);
    },
    'form.employee.model.username': v => {
      return Validator.value(v).required().email();
    },
    'form.employee.model.password': v => {
      return Validator.value(v).required().minLength(8);
    }
  }
}
</script>

<style scoped>
img.disabled-filter {
  filter: grayscale(100%);
}
</style>